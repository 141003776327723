import React, { useState } from 'react'
import styles from './about.module.scss'

import { Emoji } from '../emoji/emoji'
import { ActionButton } from '../buttons/actionButton/actionButton'
import { Modal } from '../modal/modal'
import { ThemeSwitcher } from '../themeSwitcher/themeSwitcher'
import { ProfilePicture } from './profilePicture/profilePicture'

const PersonalDescription = () => (
  <div className={styles.personalDescription}>
    <p>
      My name is{' '}
      <a href="https://mobile.twitter.com/eliamain" target="_blank" rel="noopener noreferrer">
        Elia Maino
      </a>{' '}
      I come from Italy and I am a software engineer living in Paris.
    </p>
    <p>
      <Emoji label="italian flag" emoji="🇮🇹" /> <Emoji label="pizza" emoji="🍕" />
      <Emoji label="french flag" emoji="🇫🇷" /> <Emoji label="baguette" emoji="🥖" />
    </p>
  </div>
)

export const About = () => {
  let [modalIsHidden, setModalIsHidden] = useState(true)

  const toggleModal = () => setModalIsHidden(!modalIsHidden)

  return (
    <aside className={styles.about}>
      <div className={styles.textContainer}>
        <ProfilePicture />
        <PersonalDescription />
      </div>
      <div className={styles.buttonContainer}>
        <span className={styles.themeSwitcherContainer}>
          <ThemeSwitcher />
        </span>
        <span>
          <ActionButton onClick={toggleModal}>More</ActionButton>
        </span>
      </div>
      <Modal isHidden={modalIsHidden} onClick={toggleModal} />
    </aside>
  )
}
