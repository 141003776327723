import React from 'react'
import styles from './modal.module.scss'

import { ActionButton } from '../buttons/actionButton/actionButton'
import { Relevant } from '../relevant/relevant'
import { Emoji } from '../emoji/emoji'
import { Social } from '../social/social'

export const Modal = ({ isHidden, onClick }) => {
  const displayMode = isHidden ? 'none' : 'flex'

  return (
    <div className={styles.modalBackground} style={{ display: displayMode }}>
      <div className={styles.modalContentContainer}>
        <div className={styles.closeModal}>
          <ActionButton onClick={onClick}>
            <span>&times;</span>
          </ActionButton>
        </div>
        <h1>
          Coucou <Emoji emoji={'👋'} label={'Waving hand emoji'} />
        </h1>
        <p>
          I am a <Relevant>web Software Engineer focusing on frontend development</Relevant>, I am
          used to work on large applications used by millions of users every month.
        </p>
        <p>
          I like to read and analyse code, thinking about the best architectural solution for the
          problem I need to face. I’m mostly skilled in JavaScript, but I try to keep my engineering
          skills as generic as I can (since a good solution goes beyond the chosen programming
          language).
        </p>
        <p>
          I am interested in design and product decisions too, when I work on the engineering side
          of a project, I like to do that with the product in mind.
        </p>
        <Social />
      </div>
    </div>
  )
}
