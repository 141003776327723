import React from 'react'
import styles from './articlesNavigation.module.scss'

import { LinkActionButton } from '../buttons/linkActionButton/linkActionButton'

export const ArticlesNavigation = ({ previous, next }) => {
  const hasPrevious = !!previous
  const hasNext = !!next

  return (
    <div className={styles.articlesNavigation}>
      {hasNext ? (
        <LinkActionButton to={next.fields.slug}>{next.frontmatter.title} &larr;</LinkActionButton>
      ) : (
        <span />
      )}
      {hasPrevious ? (
        <LinkActionButton to={previous.fields.slug}>
          {previous.frontmatter.title} &rarr;
        </LinkActionButton>
      ) : (
        <span />
      )}
    </div>
  )
}
