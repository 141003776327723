import React, { useState, useEffect } from 'react'

import { ActionButton } from '../buttons/actionButton/actionButton'
import { sun, moon } from './icons.js'

const STORAGE_KEY = 'user-color-scheme'
const COLOR_MODE_KEY = '--color-mode'

const getCSSCustomProp = propKey => {
  let response = getComputedStyle(document.documentElement).getPropertyValue(propKey)

  if (response.length) {
    response = response.replace(/['"]+/g, '').trim()
  }

  return response
}

export function ThemeSwitcher() {
  const [theme, setTheme] = useState(null)
  const [customThemeWasSelected, setCustomThemeWasSelected] = useState(false)
  const [icon, setIcon] = useState(theme === 'dark' ? sun : moon)

  useEffect(() => {
    const initialTheme = localStorage.getItem(STORAGE_KEY) || getCSSCustomProp(COLOR_MODE_KEY)

    setTheme(initialTheme)
    setIcon(initialTheme === 'dark' ? sun : moon)
  }, [])

  useEffect(() => {
    if (customThemeWasSelected) {
      localStorage.setItem(STORAGE_KEY, theme)
    }
    document.documentElement.setAttribute('data-user-color-scheme', theme)
  })

  const toggleTheme = () => {
    setCustomThemeWasSelected(true)

    if (theme === 'light') {
      setTheme('dark')
      setIcon(sun)
    } else {
      setTheme('light')
      setIcon(moon)
    }
  }

  return theme ? (
    <ActionButton onClick={toggleTheme}>
      Switch theme &nbsp;
      <span dangerouslySetInnerHTML={{ __html: icon }} />
    </ActionButton>
  ) : null
}
