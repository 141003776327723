import React from 'react'
import { Link } from 'gatsby'
import styles from './homeHeadingLink.module.scss'

import { Relevant } from '../relevant/relevant'

export const HomeHeadingLink = ({ isHome }) => (
  <div className={styles.homeHeadingLink}>
    {isHome ? (
      <div>
        <h1>
          This is <Relevant>eliamainnnnnn</Relevant>
        </h1>
        <i>An italian look on software engineering</i>
      </div>
    ) : (
      <Link to={'/'}>
        <h1>
          This is <Relevant>eliamainnnnnn</Relevant>
        </h1>
        <i>Go back to the home page</i>
      </Link>
    )}
  </div>
)
