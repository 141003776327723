import React from 'react'

import { Link } from 'gatsby'
import { ActionButton } from '../actionButton/actionButton'

import styles from './linkActionButton.module.scss'

export const LinkActionButton = ({ children, to }) => (
  <span className={styles.linkActionButton}>
    <Link to={to}>
      <ActionButton>{children}</ActionButton>
    </Link>
  </span>
)
