import React from 'react'
import { graphql } from 'gatsby'

import { PageLayout } from '../components/layout/pageLayout/pageLayout'
import { PagePreviewDetails } from '../components/pagePreviewDetails/pagePreviewDetails'
import { ArticlesNavigation } from '../components/articlesNavigation/articlesNavigation'
import { HomeHeadingLink } from '../components/homeHeadingLink/homeHeadingLink'

import style from './articleTemplate.module.scss'
import { VerticalLayout } from '../components/layout/verticalLayout/verticalLayout'
import { About } from '../components/about/about'

export default function Template({ data, pageContext }) {
  const post = data.markdownRemark
  const html = post.html
  const { previous, next } = pageContext

  return (
    <PageLayout
      title={`${post.frontmatter.title} | eliamainnnnnn`}
      description={post.frontmatter.title}
    >
      <VerticalLayout>
        <HomeHeadingLink />
        <article className={style.articleContainer}>
          <h1>{post.frontmatter.title}</h1>
          <PagePreviewDetails details={[post.frontmatter.date]} />
          <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
        </article>
        <About />
        <hr />
        <ArticlesNavigation previous={previous} next={next} />
      </VerticalLayout>
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
