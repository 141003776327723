import React from 'react'
import styles from './pagePreviewDetails.module.scss'

const separator = () => <>&bull;</>

export const PagePreviewDetails = ({ details }) => {
  const itemsNb = details && details.length

  return (
    <p className={styles.pagePreviewDetails}>
      {details.map((detail, i) => {
        return (
          <span key={detail}>
            {detail} {itemsNb === i + 1 ? '' : separator()}&nbsp;
          </span>
        )
      })}
    </p>
  )
}
